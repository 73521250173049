<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Clientes</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12 card">
          <div class="card-header">
            <h4>Buscar Conta</h4>
          </div>
          <div class="card-body row">
            <div class="col-md-3">
              <base-input label="Usuário / E-mail / CPF"
                          v-model="search.key"/>
            </div>
            <div class="col-md-3">
              <base-input label="Documentação Status">
                <el-select size="large"
                           placeholder="Selecionar"
                           class="select-default"
                           v-model="search.documentStatus">
                  <el-option :value="null"
                             class="select-default"
                             label="Todos"/>
                  <el-option value="PENDING_DOCUMENT"
                             class="select-default"
                             label="Pendente (PE)"/>
                  <el-option value="PENDING_VALIDATION"
                             class="select-default"
                             label="A Validar (AV)"/>
                  <el-option value="APPROVED"
                             class="select-default"
                             label="Validado (OK)"/>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input label="Ordenação Data">
                <el-select size="large"
                           placeholder="Selecionar"
                           class="select-default"
                           v-model="search.dateOrder">
                  <el-option value="ASC"
                             class="select-default"
                             label="Crescente"/>
                  <el-option value="DESC"
                             class="select-default"
                             label="Decrescente"/>
                </el-select>
              </base-input>
            </div>
            <div class="col-sm-2">
              <base-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-default"
                    v-for="item in [5,10,25,50]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input label=" ">
                <button type="button" class="btn btn-primary" @click="find">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Buscar
                </button>
              </base-input>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%"
                        empty-text="Nenhum usuário encontrada para os filtros informados.">
                <el-table-column :min-width="60" label="Usuário">
                  <template slot-scope="props">
                      <span>
                        <span><b>@{{props.row.username}}</b></span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="E-mail">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.email}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Nome">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.name}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="24" label="Doc">
                  <template slot-scope="props">
                      <span>
                        <span>{{docStatusToLabel(props.row.documentStatus)}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="50" label="Criação">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.createdDate}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Disp.">
                  <template slot-scope="props">
                      <span>
                        <i class="fas fa-circle" :style="'color: #'+(props.row.hasValidDevice?'6bd098':'ef8157')"></i>
                        <span> {{props.row.hasValidDevice?'Sim':'Não'}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Ativo">
                  <template slot-scope="props">
                      <span>
                        <i class="fas fa-circle" :style="'color: #'+(props.row.enabled?'6bd098':'ef8157')"></i>
                        <span> {{props.row.enabled?'Sim':'Não'}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Desb.">
                  <template slot-scope="props">
                      <span>
                        <i class="fas fa-circle" :style="'color: #'+(props.row.nonlocked?'6bd098':'ef8157')"></i>
                        <span> {{props.row.nonlocked?'Sim':'Não'}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :width="230" class-name="td-actions">
                  <template slot-scope="props">
                    <button class="btn btn-sm btn-info" type="button" title="Editar"
                            @click="edit(props.row.username)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button class="btn btn-sm btn-danger" type="button" title="Excluir Dispositivo"
                            @click="removeDevice(props.row)">
                      <i class="fas fa-mobile-alt"></i>
                    </button>
                    <button class="btn btn-sm btn-warning" type="button" title="Ativar/Desativar"
                            @click="enableOrDisable(props.row)">
                      <i class="fas fa-check-circle"></i>
                    </button>
                    <button type="warning" size="sm" icon title="Bloquear/Desbloquear"
                            @click="lockOrUnlock(props.row)">
                      <i class="fas fa-unlock"></i>
                    </button>
                    <span style="margin-left: 5px"
                          v-if="hasAnyAuthorization(['BO_SET_USER_OP_LIMIT','BO_DELETE_USER_OP_LIMIT']) && hasAuthorization('BO_LIST_USER_CUSTOM_OP_LIMITS')">
                      <button type="danger" size="sm" icon title="Alterar Limites Individuais"
                              @click="setUserOperationLimits(props.row.username)">
                        <i class="fas fa-asterisk"></i>
                      </button>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div slot="footer"
                 class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3"
            >
              <div class="">
                <p class="card-category">
                  Mostrando {{pagination.fromNumber}} até {{pagination.toNumber}}, de
                  {{pagination.totalNumber}} registros.
                </p>

              </div>
              <p-pagination class="float-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.loadData">
              </p-pagination>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from '../../../components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "../../../ws.service"
  import {isAuthorized, isSomeAuthorization} from "../../../util/role.utils"

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        search: {
          key: '',
          documentStatus: 'PENDING_VALIDATION',
          dateOrder: 'ASC'
        },
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    watch: {
      '$route': 'resetDefaultValues'
    },

    mounted() {
      this.loadData()
    },
    methods: {
      resetDefaultValues() {
        this.search.key = ''
        this.search.documentStatus = 'PENDING_VALIDATION',
          this.search.dateOrder = 'ASC'
        this.pagination.currentPage = 1
        this.pagination.perPage = 5
        this.pagination.pageMaxNumber = 1
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
        this.pagination.totalNumber = 0
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/bo/acc/list-account",
          true, null,
          {
            key: this.search.key,
            documentStatus: this.search.documentStatus,
            dateOrder: this.search.dateOrder,
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.accounts
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      edit(username) {

        this.openWindow('/bo/account/edit/' + username)
      },
      removeDevice(account) {
        if (!account.hasValidDevice) {
          this.$swal({
            title: 'Alerta!',
            text: 'Esta conta não possui dispositivo autorizado.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          return
        }
        this.$swal({
          title: 'Remover Dispositivo',
          text: "Você deseja realmente remover todos dispositivos dessa conta?",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'sim',
          cancelButtonText: 'CANCELAR'
        }).then((result) => {
          if (result.isConfirmed) {
            this.callRemoveDeviceWs(account)
          }
        })
      },
      callRemoveDeviceWs(account) {
        postWs("/bo/device/remove-device", true,
          {username: account.username}, null,
          () => {
            account.hasValidDevice = false
          }, failWs)
      },
      enableOrDisable(account) {
        this.$swal({
          title: 'Ativar / Desativar',
          text: "Você deseja ativar ou desativar a conta?",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ativar',
          cancelButtonText: 'DESATIVAR'
        }).then((result) => {
          if (result.isConfirmed) {
            this.callEnabledWs(account, true)
          } else if (result.dismiss == 'cancel') {
            this.callEnabledWs(account, false)
          }
        })
      },
      callEnabledWs(account, status) {
        postWs("/bo/auth/update-enabled",
          true, null,
          {username: account.username, status: status},
          function () {
            account.enabled = status
          }, failWs)
      },
      lockOrUnlock(account) {
        this.$swal({
          title: 'Bloquear / Desbloquear',
          text: 'Você deseja bloquear ou desbloquear a conta?',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'desbloquear',
          cancelButtonText: 'BLOQUEAR'
        }).then((result) => {
          if (result.isConfirmed) {
            this.callNonlockedWs(account, true)
          } else if (result.dismiss == 'cancel') {
            this.callNonlockedWs(account, false)
          }
        })
      },
      callNonlockedWs(account, status) {
        postWs("/bo/auth/update-nonlocked",
          true, null,
          {username: account.username, status: status},
          function () {
            account.nonlocked = status
          }, failWs)
      },
      setUserOperationLimits(username) {
        this.openWindow('/bo/user-operation-limit/edit/' + username)
      },
      docStatusToLabel(documentStatus) {
        if (documentStatus == null) {
          return 'PE*'
        } else if (documentStatus == 'PENDING_DOCUMENT') {
          return 'PE'
        } else if (documentStatus == 'PENDING_VALIDATION') {
          return 'AV'
        } else if (documentStatus == 'APPROVED') {
          return 'OK'
        }
        return '??'
      },
      openWindow(url) {
        try {
          window.location.href = url
        } catch (ex) {
          this.$swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
        }
      },
      hasAuthorization(role) {
        return isAuthorized(role);
      },
      hasAnyAuthorization(roles) {
        return isSomeAuthorization(roles);
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
